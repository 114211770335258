import { createRouter, createWebHashHistory } from 'vue-router'
import Root from '../views/Root.vue'
import AuthMiddleware from './auth.guard'

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root,
    meta: { title: 'Eyriac' }
  },
  {
    path: '/login',
    name: 'LogIn',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LogIn.vue'),
    meta: { title: 'Eyriac Login' }
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Signup.vue')
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { protected: true, title: "Eyriac - Home" }
  },
  {
    path: '/waitvalidation',
    name: 'WaitValidation',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/WaitValidation.vue'),
    // meta: { protected: true },
    meta: { protected: false, title: 'Eyriac - Wait for validation' }
  },
  {
    path: '/accessdenied',
    name: 'AccessDenied',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/AccessDenied.vue'),
    // meta: { protected: true },
    meta: { title: 'Eyriac - Access Denied' }
  },
  {
    path: '/profile',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Profile.vue'),
    meta: { protected: true, title: 'Eyriac - Profile'/*, adminProtected: true*/ }
  },
  {
    path: '/members',
    name: 'Members',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Members.vue'),
    meta: { protected: true, title: 'Eyriac - Membres'/*, adminProtected: true*/ }
  },
  {
    path: '/administration',
    name: 'Administration',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Administration.vue'),
    meta: { protected: true, title: 'Eyriac - Administration', adminProtected: true }
  },
  {
    path: '/102ans',
    name: '102ans',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/102ans/102ans.vue'),
    meta: { protected: true, title: 'Eyriac - 102ans', adminProtected: false }
  },
  {
    path: '/102ans/vendredi',
    name: 'Vendredi',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/102ans/Vendredi.vue'),
    meta: { protected: true, title: 'Eyriac - 102ans Vendredi', adminProtected: false }
  },
  {
    path: '/102ans/samedi',
    name: 'Samedi',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/102ans/Samedi.vue'),
    meta: { protected: true, title: 'Eyriac - 102ans Samedi', adminProtected: false }
  },
  {
    path: '/102ans/dimanche',
    name: 'Dimanche',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/102ans/Dimanche.vue'),
    meta: { protected: true, title: 'Eyriac - 102ans Dimanche', adminProtected: false }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(AuthMiddleware)

export default router
