
<template>
  <div id="app">
    <router-view/>
  
    <!--<authenticator :login-mechanisms="['email']">-->
    <!--  <template v-slot="{ signOut }">-->
    <!--    <button @click="signOut">Sign Out</button>-->
    <!--  </template>-->
    <!--</authenticator>-->
  </div>
</template>
<script>
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
// import { Authenticator } from "@aws-amplify/ui-vue";
// import "@aws-amplify/ui-vue/styles.css";
import { Auth, Hub } from 'aws-amplify'
import { mapActions } from 'vuex';


export default {
  name: 'App',
  methods: {
    ...mapActions('authentication', [
      'signInUser',
      'logoutUser',
      'loginUser',
      'logoutUser',
    ]),
  },
  beforeCreate() {
    Hub.listen('auth', data => {
      console.log('data:', data)
      const { payload } = data
      if (payload.event === 'signIn') {
        this.signInUser(payload.data)
        // this.$router.push('/profile')
      }
      // if (payload.event === 'signOut') {
      //   // this.$router.push('/auth')
      //   this.logoutUser()
      // }
    })
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true
      })
      .catch(() => this.signedIn = false)
  }
  // data() {
  //   return {
  //     user: { },
  //     signUpFields: [
  //       { type: 'username', label: 'Username (email) *', required: true },
  //       { type: 'password', label: 'Password *', required: true},
  //       { type: 'name', label: 'Name *', required: true}
  //     ]
  //   }
  // },
  // created() {
  //   onAuthUIStateChange((state, user) => {
  //     if  (state === AuthState.SignedIn) {
  //       this.user = user;
  //       console.log(user);
  //     }
  //   })
  // }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
