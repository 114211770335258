<template>
  <div class="flex w-full h-screen bg-white"> 
    <!-- left fide -->
    <div class="flex items-center justify-center w-full h-full">
        <div class="flex flex-col font-bold">
             <!--<div class="flex items-center"> -->
                <!-- <i class="fas fa-search text-white text-2xl mr-5"></i> -->
                <p class="text-black text-4xl">Bienvenue à Eyriac !</p>
             <!--</div> -->
             <!--<div class="flex items-center"> -->
                <button @click.prevent="showSignInPage" class="rounded-full bg-yellow text-lg text-black mt-5 p-3 hover:bg-darkyellow">Se connecter ou créer un compte</button>
             <!--</div> -->
            <!-- <div class="flex items-center mt-5"> -->
                <!--<button @click.prevent="showSignUpPage" class="rounded-full bg-blue text-lg text-white mt-5 p-3 hover:bg-darkblue">Demander un compte</button>-->
                <!-- <i class="fas fa-comment text-white text-2xl mr-5"></i>
                <p class="text-white font-bold text-xl">Join the conversation</p> -->
            <!-- </div> -->
        </div>
    </div>
    
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'; 

export default {
  name: 'Root',
//   computed: {
//       ...mapGetters('signup', [
//           'showModal'
//       ]),
//   },
  methods: {
      showSignInPage() {
          this.$router.push("LogIn");
      },
      showSignUpPage() {
          this.$router.push("Signup");
      },
    //   ...mapActions('signup', [
    //       'setSignupStep'
    //   ]),
  }

//   computed: {
//       ...mapGetters('authentication', [
//           'loggedIn',
//           'user'
//       ]),
//   },
//   methods: {
//       ...mapActions('authentication', [
//           'loginUser',
//           'logoutUser' 
//       ]),
//   },
//   created() {
//       onAuthUIStateChange(async (state, user) => {
//           if (state === AuthState.SignedIn) {
//             //   this.user = user;
//               await this.loginUser(user);
//           } if (state === AuthState.SignedOut) {
//             //   this.user = undefined
//               await this.logoutUser();
//           }
//       })
//   }
}
</script>

<style>
div {
    xborder: 1px dashed blue;
    xpadding: 15px;
}
div.items-center.justify-center {
    xbackground-color: rgba(240, 240, 240, 0.5);
}
</style>