import { Auth } from 'aws-amplify'
import router from '../../../router'

export default {
    loginUser({ commit }, user) {
        commit("USER_LOGIN", user);
    },
    async logoutUser({ commit }) {
        console.log("Authentication - logoutUser")
        await Auth.signOut({
            global: true
        })
        commit("USER_LOGOUT");
        router.push('/Login')
    },
    async signUp({ commit }, form) {
      const user = await Auth.signUp({
        username: form.email,
        password: form.password,
        attributes: {
          name: form.firstname
        }
      });
      commit("USER_SIGNUP", user);
    },
    async confirmSignUp(_, form) {
      await Auth.confirmSignUp(form.email, form.verificationCode)
    },
    async resendSignUp(_, form) {
      await Auth.resendSignUp(form.email);
    },

    async signInUser({ dispatch }, user) {
      // const user = await Auth.signIn(form.email, form.password);
      console.log("Authentication - Sign in user: ", user)
      await dispatch("loginUser", user);
      // await dispatch("profile/setProfile", null, { root: true });
      router.push({ name: 'Home' }).catch(() => {});
    },
  
    async loginUserIfAlreadyAuthenticated({ dispatch }) {
      const user = await Auth.currentUserInfo();
      if (user) {
        console.log('user is logged in already')
        await dispatch("loginUser", user);
        // await dispatch("profile/setProfile", null, { root: true });
      }
    }
}