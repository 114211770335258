import { Auth } from 'aws-amplify';

export default async (to, from, next) => {
    console.log("to", to)
    const isProtected = to.matched.some(route => route.meta.protected);
    const adminProtected = to.matched.some(route => route.meta.adminProtected);
    console.log("isProtected", isProtected)
    console.log("adminProtected", adminProtected)
    const loggedIn = await Auth.currentUserInfo();
    console.log("loggedIn", loggedIn)
    
    const DEFAULT_TITLE = 'Eyriac';
    document.title = to.meta.title || DEFAULT_TITLE;
    
    let session, groups, admin
    if (loggedIn){
        session = await Auth.currentSession()
        groups = session.idToken.payload["cognito:groups"]
        console.log("groups", groups)
        if (groups) {
            if (groups.find(element => element == "administrators")){
                console.log("User is admin")
                admin = true
            }
        }
    }
    
    // If not logged in
    console.log("test1")
    if (isProtected && !loggedIn) {
        console.log("User is not logged in")
        next("/");
        return;
    }
    
    // If no groups is associated with the user 
    console.log("test2")
    if (isProtected && !groups) {
        console.log("User has no group")
        next("/waitvalidation");
        // next({ name: 'WaitValidation' , replace: true})
        return;
    }

    console.log("test3")
    if (adminProtected && !admin) {
        console.log("user is not in admin group")
        next("/accessdenied");
        return;
    }
    next();
    console.log("test4")
}
