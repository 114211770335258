import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue'
import router from './router'
import store from './store'

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    mandatorySignIn: true
  },
  API: {
      endpoints: [
          {
              name: "eyriacAPI",
              endpoint: process.env.VUE_APP_EYRIAC_API
          }
      ]
  }
})

createApp(App).use(router).use(store).mount('#app')
