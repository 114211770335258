import { createStore } from "vuex";
import authentication from './modules/authentication'
// import signup from './modules/signup'
// import profile from './modules/profile'

const store = createStore({
  modules: {
      authentication,
      // signup,
      // profile,
  }  
})

export default store